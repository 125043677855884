












import Vue from "vue";
export default Vue.extend({
	data() {
		return {
			members: []
		};
	},
	created() {
		let filterList = this.getClub.members.filter(x => x.isPass).map(x => x.user);
		this.$store.commit("pushPageLoading", {
			name: "GET_CLUB_MEMBERS",
			message: "동아리 멤버 불러오는 중"
		});
		this.$store
			.dispatch("GET_CLUB_MEMBERS")
			.then(members => {
				console.log(members);
				this.$store.commit("clearPageLoading", "GET_CLUB_MEMBERS");
				this.members = members.filter(x => filterList.indexOf(x._id) != -1);
			})
			.catch(err => {});
	},
	methods: {
		getFilterEmail(email: string): string {
			let splitEmail: string[] = email.split("@");
			return `${splitEmail[0].substr(0, splitEmail[0].length - 2)}**@ ${splitEmail[1]}`;
		}
	},
	computed: {
		getClub() {
			return this.$store.state.club;
		}
	}
});
